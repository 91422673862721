<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="[(showModalCreate = true), (form = {})]"
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedRoles || !selectedRoles.length"
              />
            </div>
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="roles"
          v-model:selection="selectedRoles"
          dataKey="_id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Membership Voucher List</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="Voucher title" header="Voucher Title" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              {{ slotProps.data.title }}
            </template>
          </Column>
          <Column field="limit" header="limit" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              {{ slotProps.data.limit }}
            </template>
          </Column>
          <Column field="Code" header="Code" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              {{ slotProps.data.voucher_code }}
            </template>
          </Column>
          <Column field="total_usage" header="Total Usage" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              {{ slotProps.data.total_usage }}
            </template>
          </Column>
          <Column field="scheduled" header="Scheduled" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Code</span>
              {{ slotProps.data.is_scheduled ? "Yes" : "No" }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" header="Actions">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" @click="showEditModal(slotProps.data)" />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger mt-2"
                @click="confirmDeleteItem(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <!-- create -->
        <Dialog v-model:visible="showModalCreate" :style="{ width: '750px' }" header="Add Role" :modal="true" class="p-fluid">
          <div class="field">
            <label for="username">title :</label>
            <InputText id="username" v-model.trim="form.title" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Description :</label>
            <ckeditor :editor="editor" v-model="form.description" :config="editorConfig"></ckeditor>
          </div>
          <div class="field">
            <label for="email">Usage Limit :</label>
            <InputText id="email" v-model.trim="form.limit" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Usage Limit Per User :</label>
            <InputText id="email" v-model.trim="form.user_limit" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Code :</label>
            <InputText id="email" v-model.trim="form.voucher_code" required="true" autofocus />
          </div>
          <div class="field">
            scheduled ?
            <InputSwitch v-model="form.is_scheduled" />
          </div>
          <div v-if="form.is_scheduled" class="field">
            <label for="email">Start Date :</label>
            <Calendar v-model="form.start_date" :inline="true" />
          </div>

          <div v-if="form.is_scheduled" class="field">
            <label for="email">End Date :</label>
            <Calendar v-model="form.end_date" :inline="true" />
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalCreate = false" />
            <Button label="Create" icon="pi pi-check" class="p-button-text" :loading="isLoading" @click="createRole" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
            <Button
              :label="isLoading ? 'Creating' : 'Create'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <!-- Delete Modal -->
        <Dialog v-model:visible="showModalDeleteItem" :style="{ width: '450px' }" header="Delete Role Confirm ?" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to delete the selected rvouche?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="showModalDeleteItem = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="isLoading" @click="deleteItem" />
          </template>
        </Dialog>

        <!-- edit  -->
        <Dialog v-model:visible="showModalEdit" :style="{ width: '750px' }" header="Edit User" :modal="true" class="p-fluid">
          <div class="field">
            <label for="username">title :</label>
            <InputText id="username" v-model.trim="form.title" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Description :</label>
            <ckeditor :editor="editor" v-model="form.description" :config="editorConfig"></ckeditor>
          </div>
          <div class="field">
            <label for="email">Usage Limit :</label>
            <InputText id="email" v-model.trim="form.limit" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Usage Limit Per User :</label>
            <InputText id="email" v-model.trim="form.user_limit" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Code :</label>
            <InputText id="email" v-model.trim="form.voucher_code" required="true" autofocus />
          </div>
          <div class="field">
            scheduled ?
            <InputSwitch v-model="form.is_scheduled" />
          </div>
          <div v-if="form.is_scheduled" class="field">
            <label for="email">Start Date :</label>
            <Calendar v-model="form.start_date" :inline="true" />
          </div>

          <div v-if="form.is_scheduled" class="field">
            <label for="email">End Date :</label>
            <Calendar v-model="form.end_date" :inline="true" />
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalEdit = false" />
            <Button
              :label="isLoading ? 'Updating' : 'Update'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="editUser"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../../service/ProductService";
import VoucherApi from "../../api/Voucher";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,

      editorConfig: {
        // contentsCss:
        //   "https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&family=Kiwi+Maru:wght@300;400;500&display=swap",
        // font_names:
        //   "Arial/Arial, Helvetica, sans-serif;" +
        //   "Comic Sans MS/Comic Sans MS, cursive;" +
        //   "Courier New/Courier New, Courier, monospace;" +
        //   "Georgia/Georgia, serif;" +
        //   "Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;" +
        //   "Tahoma/Tahoma, Geneva, sans-serif;" +
        //   "Times New Roman/Times New Roman, Times, serif;" +
        //   "Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;" +
        //   "Verdana/Verdana, Geneva, sans-serif;" +
        //   "Kiwi_maru/Kiwi maru;" +
        //   "Karla/Karla",
        // extraPlugins: "uploadimage,colorbutton, font, ",
        // filebrowserUploadUrl: "https://api.wishplease.id/page-static/upload",
        // colorButton_colors:
        //   "707070,121212,1D4B98,e06040,419f5a,79aacb,ffd44f,f5ede0",
        // colorButton_enableMore: true,
      },
      products: null,
      showModalCreate: false,
      showModalEdit: false,
      showModalDeleteItem: false,
      deleteProductDialog: false,
      showModalDelete: false,
      product: {},
      roles: [],
      selectedRoles: [],
      isLoading: false,
      form: {
        name: "",
      },
      role: [
        { label: "ADMIN", value: "admin" },
        { label: "USER", value: "user" },
      ],
    };
  },
  productService: null,
  created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  mounted() {
    this.productService.getProducts().then((data) => (this.products = data));
    this.getRoles();
  },
  methods: {
    confirmDeleteItem(user) {
      this.showModalDeleteItem = true;
      this.temp_user = user;
    },
    async showEditModal(user) {
      try {
        this.form = {
          ...user,
          role_id: {
            label: user.role_id ? user.role_id.name : "",
            value: user.role_id ? user.role_id._id : "",
          },
          start_date: new Date(user.start_date),
          end_date: new Date(user.end_date),
        };

        this.showModalEdit = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getRoles() {
      try {
        const response = await VoucherApi.Get();
        if (response.data.status === 200) {
          this.roles = response.data.data.filter((el) => el.voucher_type == 3);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async createRole() {
      this.isLoading = true;
      try {
        const response = await VoucherApi.Add({
          ...this.form,
          voucher_type: 3,
          is_scheduled: this.form.is_scheduled ? this.form.is_scheduled : false,
        });
        if (response.data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "New Voucher membership Created!",
            life: 3000,
          });

          this.getRoles();
          this.isLoading = false;
          this.form = {};
          this.showModalCreate = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async deleteMultipleRole() {
      this.isLoading = true;
      try {
        //   reset selected roles
        let payload = {
          id: [],
        };

        for (let i = 0; i < this.selectedRoles.length; i++) {
          payload.id.push(this.selectedRoles[i]._id);
        }

        const response = await VoucherApi.DeleteMultiple(payload);
        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: response.data.message,
            life: 3000,
          });
          this.getRoles();
          this.isLoading = false;
          this.selectedRoles = [];
          this.showModalDelete = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteItem() {
      try {
        this.isLoading = true;
        const response = await VoucherApi.Delete(this.temp_user._id);

        if (response.data.status === 200) {
          this.isLoading = false;
          this.showModalDeleteItem = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: `${this.temp_user.title} Deleted!`,
            life: 3000,
          });
          this.getRoles();
          this.temp_user = {};
        }
      } catch (error) {
        console.log(error);
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
    async editUser() {
      try {
        this.isLoading = true;
        let submit_data = {
          ...this.form,
        };
        const response = await VoucherApi.Update(this.form._id, submit_data);

        if (response.data.status === 200) {
          this.getRoles();
          this.form = {};
          this.isLoading = false;
          this.showModalEdit = false;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "User Updated!",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Update Failed!",
            detail: "Something went wrong",
            life: 3000,
          });
          this.showModalEdit = false;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    confirmDeleteSelected() {
      this.showModalDelete = true;
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
